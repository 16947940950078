.outer-dashed-border {
    width: 120px;
    height: 115px;
    border: 1.4px dashed black;
}

.submit-large-purple {
    background: #96a0ff;
    width: 210px;
    height: 44px;
    border-radius: 5px;
}

.submit-large-blue {
    background: #2f44ff;
    width: 210px;
    height: 44px;
    border-radius: 5px;
}

.modal-container {
    width: 55%;
}

.success-img {
    width: 40%;
}

.select-resume-img {
    width: 100%;
}

.resume-file-img {
    width: 90%;
    height: 70%;
}

.modal-close-button {
    right: 35px;
}

.custom-hover-pointer :hover {
    cursor: pointer;
}

.options-resume-mobile {
    margin-bottom: 40px;
}

.social-media-icons {
    width: 35px;
    height: 35px;
}

.copy-button {
    background: #2F44FF;
    border:none;
    width: 50px;
    height: 40px;
    border-radius: 10px;
    margin-left: -10px;
}

.fa-clone {
    color: white;
}

.options-resume-mobile:focus {
    background: blue !important;
}

.disable-select-resume:hover {
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {

    .modal-container {
        width: 94%;
        height: 70%;
    }

    .modal-container-intermediate {
        width: 85%;
        height: 95%;
    }

    .submit-large {
        margin-top: -10%;
    }

    .select-resume-img {
        width: 75%;
    }

    .success-img {
        width: 45%;
    }

    .outer-dashed-border {
        width: 75%;
        height: 60%;
        margin-top: -5px;
    }

    .submit-large-purple {
        margin-top: 1%;
    }

    .options-resume-select {
        flex-direction: column;
    }

    .resume-file-img {
        height: 60px;
        width: 60px;
    }

    .user-icon-resume {
        font-size: 70px;
    }

    .options-resume-mobile {
        margin-bottom: -5px;
        margin-top: -5px;
    }
    
    .social-media-icons {
        width: 35px;
        height: 35px;
    }
}