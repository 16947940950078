.td-upload-work-container {
    padding: 0 26px;
}

.task-details-bg-container {
    background-image: linear-gradient(89.8deg, rgba(5, 2, 21, 0.56) -1.83%, rgba(6, 6, 6, 0.3136) 93.34%), url("../Media/Task-details-img.jpg");
    background-size: cover;
}

.task-items-para {
    padding: 0 3rem;
}

.tag-items {
    padding: 8px 15px;
    background: #EFF1FF;
    border-radius: 5px;
    margin: 0 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
}

.task-details-bg-container {
    max-width: 100%;
    overflow-x: hidden;
}

.task-details-bg-lower-container {
    padding: 40px 0 !important;
}

.ts-sc-container {
    border-left: 1px dashed #D3D4D8;
    margin: 0 70px;
    padding: 0 50px 70px;
}

.heading-number-container {
    height: 50px;
    width: 50px;
    background:#000339;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    left:-75px;
}

.task-details-task-items {
    margin-top: 70px !important;
}

.resource-item-container {
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    margin-bottom: 20px;
}

.task-items-link {
    text-decoration: none;
    color: #2F44FF;
}

.task-items-link:hover {
    cursor: pointer;
}

.instruction-video-play {
    margin-left: 30px;
    border-radius: 10px;
}

.files-upload-img {
    object-fit: contain;
    width: 100%;
}

.work-submit-file-button:hover {
    cursor: pointer;
}

.td-last-container {
    margin: 0 70px !important;
    padding: 0 50px;
}

.td-last-heading-number-container {
    position: absolute;
    height: 50px;
    width: 50px;
    background:#000339;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    left: -25px;
}

@media screen and (max-width: 992px) {

    .files-upload-img {
        object-fit: contain;
        width: 50%;
    }

    .task-items-files-img-container {
        display: flex;
        justify-content: center;
    }

    .instruction-video-play-container {
        padding: 0 !important;
        display: flex;
        justify-content: center;
    }

}

@media screen and (max-width: 768px) {

    .task-details-bg-container {
        background-image: url("../Media/Task-details-img-phone.png");
        padding: 6%;
    }

    .task-items-para {
        padding: 0 0rem;
    }

    .tag-items {
        font-size: 10px !important;
        padding: 4px 14px !important;
    }

    .ts-sc-container {
        border-left: 0px solid black;
        margin: 0 55px;
        padding: 0 0px;
    }

    .td-last-container {
        margin: 50px 55px 0 55px !important;
        padding: 0 0px;
        justify-content: center;
    }

    .heading-number-container {
        height: 30px;
        width: 30px;
        background:#000339;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: -40px;
    }

    .instruction-video-play {
        margin-left: 00px;
        border-radius: 10px;
        width: 120%;
        padding: 0 !important;
        margin-top: 10px;
    }

    .td-upload-work-container {
        justify-content: center;
        padding: 0 10px;
    }

    .task-details-bg-lower-container {
        padding: 1rem 0 2rem 0 !important;
    }

    .td-last-heading-number-container {
        height: 30px;
        width: 30px;
        background:#000339;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: -40px;
    }

    .task-details-task-items {
        margin-top: 50px !important;
    }

}