.task-module-img {
    object-fit: contain;
    width: 100%;
    max-height: 180px;
}

.task-item-para {
    font-size: 14px;
}

.task-item-heading {
    font-size: 18px;
}

.tags-container {
    width: 75%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.task-details-container {
    padding-top: 0.7rem;
}

.tags-items {
    font-size: 13px;
    padding: 5px 15px;
    background: #EFF1FF;
    border-radius: 5px;
    margin: 5px;
}

.task-completed-tag {
    text-decoration: none !important;
    color: #14C944;
}

@media screen and (max-width: 992px) {

    .tags-container {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {

    .tags-container {
        width: 100%;
    }

    .task-item-para {
        font-size: 12px;
    }

    .task-item-heading {
        font-size: 14px;
    }

    .tags-items {
        font-size: 10px;
        background: #EFF1FF;
        padding: 5px;
        border-radius: 5px;
    }

    .task-details-container {
        padding-top: 0rem;
    }

}

@media screen and (max-width: 576px) {

    .tags-container {
        width: 100%;
    }

}
