.ve-details-main-container {
    max-width: 100%;
    overflow-x: hidden;
}

.ve-details-bg-container {
    background-image: linear-gradient(89.8deg, rgba(5, 2, 21, 0.56) -1.83%, rgba(6, 6, 6, 0.3136) 93.34%), url("../Media/ve_bg_img.png") !important;
    background-size: cover !important;
}

.desc-img {
    object-fit: contain;
}

.ve-details-desc-heading {
    margin: 20px 50px 10px;
    font-size: 22px;
}

.ve-details-enroll-button {
    background: white;
    padding: 10px 40px;
    border: none;
    border-radius: 8px;
}

.ve-details-upper-container {
    padding: 0 0 0 8%;
}

.ve-details-desc-para {
    font-size: 18px;
    line-height: 36px;
    padding: 0px 60px !important;
}

.ve-details-org-img-container {
    padding: 0 5% !important;
    margin: 70px 30px;
}

.ve-details-org-img {
    object-fit: contain;
}

.ve-details-modules-container {
    padding:0 4%;
}

.feedback-user-img {
    object-fit: contain;
    margin-right: 20px;
}

.feedback-para {
    font-size: 18px;
    padding: 40px 50px !important;
}

.feedback-user-details {
    padding: 50px !important;
}

.carousel-indicators {
    display: none;
}

.quote-open-symbol {
    position: absolute;
    top: -4%;
    left: 2%;
}

.carousel-control-next {
    background: #000339 !important;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    top: -35px !important;
    right: 80px !important;
}

.carousel-control-prev {
    position: absolute !important;
    background: #000339 !important;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    top: -35px !important;
    right: 130px !important;
    left: auto !important;
}

.carousel-control-prev-icon,.carousel-control-next-icon {
    width: 20px !important;
}

.ve-individual-container {
    margin-top: 5rem;
}

@media screen and (max-width: 768px) {

    .ve-details-bg-container {
        background-image: url("../Media/ve_bg_mob.png") !important;
        background-repeat: no-repeat;
        background-size: cover !important;
        padding: 20px;
    }

    .ve-details-upper-container {
        padding: 0;
    }

    .feedback-user-details {
        margin: 10px 0 0 !important;
        font-size: 14px;
    }

    .ve-details-desc-heading {
        font-size: 14px;
        margin: 30px 20px 10px;
    }

    .ve-details-desc-para {
        padding: 30px 10px !important;
        font-size: 14px;
    }

    .ve-details-org-img-div {
        margin: 20px !important;
    }

    .feedback-para {
        padding: 30px 30px !important;
        font-size: 14px !important;
    }

    .quote-open-symbol {
        position: absolute;
        top: -70px;
        left: -10px;
        padding: 40px;
    }

    .carousel-control-next {
        top: -35px !important;
        right: 30px !important;
    }

    .carousel-control-prev {
        top: -35px !important;
        right: 80px !important;
    }

    .ve-individual-container {
        margin-top: 1rem;
    }

}