.enrolled-ve-heading-container {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 20px;
}

.enrolled-ve-main {
    margin: 20px 0 70px;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.enrolled-ve-main::-webkit-scrollbar {
    display: none;
}