.virtual-experience-container {
    display: flex;
    padding: 5px;
    min-width: 360px;
    height: 150px;
    border: solid 1px rgb(238, 238, 238);
    border-radius: 5px;
    margin: 5px 15px;
    box-shadow: 1px 1px 10px 1px rgb(233, 232, 232);
}

@media screen and (max-width: 576px) {
    .virtual-experience-container {
        min-width: 320px;
        margin: 5px 8px;
    }
}