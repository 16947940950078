.icon-round-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50px;
    background-color: #F8F8F8;
}

.close-preview-btn {
    position: absolute;
    right: 10px;
    top: 10px
}

.job-preview-company-logo {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
