.filter-icon-container {
    margin-top: 15px;
    margin-right: 5px;
    padding-right: 15px;
    display: none;
    width: 40px;
    height: 40px;
    font-size: 22px;
}

.tabs-container {
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.tabs-container::-webkit-scrollbar {
    display: none;
}

.tab {
    cursor: pointer;
    text-align: center;
    color: #000339;
    font-weight: 600;
    font-size: 16px;
    padding: 20px 10px 5px 10px;
    border-bottom: 5px solid white;
    transition-duration: 0.4s;
}

.active-tab {
    border-bottom: 5px solid #000339;
}

.prepare-container {
    margin-top: 150px;
    text-align: center;
    font-size: large;
}

.filter-tabs-container {
    max-width: 1000px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.filter-tab {
    border-radius: 50px;
    border: none;
    color: #000339;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 20px;
}

/* bootstrap class is overridden to make the filter drop down scrollable */
.filter-tab + .dropdown-menu {
    padding: 8px 15px 0;
    font-size: 12px;
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}

.custom-dropdown-icon {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 8px;
}

.active-filter-tab {
    background-color: #000339;
    color: white;
}

.dropdown-toggle::after {
    display: none !important;
}

@media screen and (max-width: 992px) {
    .filter-icon-container {
        display: block;
    }

    .filter-tabs-container {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .tab {
        min-width: 130px;
    }

}