*{
    font-family: 'Montserrat', sans-serif;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-17 {
    font-size: 17px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-22 {
    font-size: 22px;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.text-navy-blue {
    color: #000339;
}

.bg-navy-blue {
    background-color: #000339 !important;
}

.light-bg {
    background-color: rgb(238, 238, 238);
}

.light-text {
    color: #787878;
}

.text-purple {
    color: #2F44FF;
}

.apli-btn {
    background-color: #2F44FF;
    padding: 8px 20px;
    font-weight: bold;
    border-radius: 8px;
    color: white;
    font-size: 13px;
    border: 0;
}

.apli-btn:hover {
    background-color: #000339;
}

.btn-large {
    padding: 8px 30px;
}

.btn-x-large {
    padding: 8px 50px;
}

.btn-xx-large {
    padding: 8px 70px;
}

.text-justify {
    text-align: justify;
}
