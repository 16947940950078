.community-access-link {
    text-decoration: none;
    color: #2F44FF;
}

.community-access-container {
    min-height: 73vh;
}

@media screen and (max-width: 576px) {
    
    .community-access-desc {
        font-size: 14px;
    }

    .community-access-link-desc {
        font-size: 18px;
    }

}