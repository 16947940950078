.navbar-main {
    height: 70px;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
    padding: 5px 50px;
    flex-wrap: wrap;
    box-shadow: 0 0 5px 1px rgb(184, 184, 184);
    z-index: 1000;
}

.nav-burger {
    display: none;
    max-width: 40px;
    flex-grow: 1;
}

.burger-btn {
    max-width: 40px;
    border: none;
    outline: none;
    background-color: white;
}

.nav-brand-container {
    flex-grow: 1;
    height: 90%;
}

.nav-content-container {
    flex-grow: 3;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-content {
    text-align: center;
    margin: 0 20px;
}

.nav-text-container {
    margin-top: 10px;
    display: block;
    text-decoration: none;
    color: rgb(0, 0, 46);
    transition-duration: 0.7s;
    border-bottom: solid 4px white;
}

.nav-text {
    text-decoration: none;
    margin-top: 3px;
    font-size: 10px;
    font-weight: 500;
}

.nav-text:focus {
    color: red;
}

.nav-profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.nav-profile-container {
    max-width: 40px;
    flex-grow: 1;
    text-align: center;
}

.nav-profile {
    font-size: 36px;
}

.nav-search-container {
    flex-grow: 3;
    background-color: rgb(245, 245, 245);
}

.nav-notification-container {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    margin-top: 10px;
}

.nav-notification {
    font-size: 24px;
}

.dropdown-toggle-icon {
    background: #fff;
    border:none;
    margin-top: -13px;
}

.dropdown-menu-custom {
    border-radius: 15px !important;
    border: 0 !important;
    box-shadow: 0 3px 5px 1px #d6d3d3;
}

.profile-dropdown-menu {
    min-width: 350px;
    padding: 15px 30px;
}

.user-img-border {
    border-radius: 50%;
    border: 1.5px #2F44FF solid;
}

@media screen and (max-width: 992px) {
    .nav-search-container {
        flex-grow: 5;
    }
    
    .nav-content-container {
        flex-grow: 2;
    }
}

@media screen and (max-width: 768px) {
    .nav-content-container, .nav-notification-container, .nav-search-container {
        display: none;
    }
    
    .nav-burger {
        display: block;
    }
    
    .nav-brand-container {
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    
}