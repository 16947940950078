.custom-bg-success {
    background-color: #87e087;
}

.custom-bg-danger {
    background-color: #ff9090;
}

.main-chat-container {
    margin: auto;
    padding: 15px 15px 30px;
    width: 95%;
    height: 74vh;
    background-color: #313644;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.main-chat-container::-webkit-scrollbar {
    display: none;
}

/* .main-chat-container:last-child .chatbox-main-container::after {
    content: 'Loading...';
    background-color: red;
} */

.chatbox-main-left-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    max-width: 80%;
}

.chatbox-main-right-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    max-width: 80%;
    flex-direction: row-reverse;
    align-self: end;
}

.chatbox-main-center-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    max-width: 80%;
    align-self: center;
    justify-content: center;
}

.chatbox-avatar-container {
    width: 40px;
    height: 40px;
    align-self: self-end;
    border-radius: 50%;
    overflow: hidden;
}

.chatbox-text-container {
    margin: 0 15px;
    padding: 12px;
    /* border-radius: 30px; */
    color: white;
    max-width: 80%;
    font-size: 16px;
    font-weight: 500;
}

.chatbox-text-left {
    border-radius: 20px 20px 20px 0px;
    background-color: #424657;
}

.chatbox-text-right {
    border-radius: 20px 20px 0px 20px;
    background-color: #2F69FF;
}

.loader {
    text-align: center;  
    margin: 5px;  
}
.loader span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    background: rgb(179, 179, 179);
    border-radius: 20px;
    animation: loader 0.8s infinite alternate;
}

.loader span:nth-of-type(2) {
    animation-delay: 0.2s;
}

.loader span:nth-of-type(3) {
    animation-delay: 0.6s;
}

.answer-choices-container {
    flex-wrap: wrap;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
}

span.answer-choice {
    margin: 5px;
    background: white;
    color: black;
    padding: 5px 8px;
    border-radius: 5px;
}

.chat-img-container {
    max-width: 380px;
    position: relative;
    cursor: pointer;
}

.chat-img-container img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.chat-doc-container {
    padding: 15px 20px;
    max-width: 150px;
    color: white;
}

.chat-img-container i {
    font-size: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.chat-doc-container i {
    font-size: 60px;
    color: white;
}

.window-switching-container {
    margin: 0 auto 5px;
    padding: 30px 5px 15px;
    width: 95%;
    display: flex;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    background-color: #313644;
}

.window-switching-container button {
    margin: 0 20px;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
}

.window-switching-container  .btn-prev {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.window-switching-container  .btn-next {
    background-color: white;
    border: 0;
    color: #424657;
}

.window-heading-container {
    color: white;
    margin: 5px auto 0;
    width: 95%;
    padding: 20px 0px 0px;
    text-align: center;
    background: linear-gradient(45deg, #926DFB, #6284FB);
    border-radius: 8px 8px 0 0;
}

.window-heading-container .progress-bar {
    margin-top: 20px;
    height: 8px;
    width: 100%;
    background-color: #D7E7FF;
}

.task-success-container {
    width: 70%;
    height: 90vh;
    background-color: #313644;
    color: white;
    margin: 15px auto;
    border-radius: 8px;
    text-align: center;
    padding: 6% 0;
}

.task-success-btn-container {
    margin: auto;
    margin-top: 60px;
    width: 40%;
    display: flex;
    justify-content: space-evenly;
}

.back-ve-btn {
    background: transparent;
    width: 160px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.next-mission-btn {
    background: white;
    width: 160px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #313644;
}

.task-upload-container {
    margin-top: 60px;
    padding: 10px 50px;
    border: 2px dotted white;
    border-radius: 8px;
    cursor: pointer;
    background-color: #424657;
}

@keyframes loader {
    0% {
        opacity: 0.9;
        transform: scale(0.5);
    }
    100% {
        opacity: 0.1;
        transform: scale(1);
    }
}

@media screen and (max-width: 768px) {
    .main-chat-container, .window-switching-container, .window-heading-container {
        width: 90%;
    }

}

@media screen and (max-width: 560px) {
    .main-chat-container, .window-switching-container, .window-heading-container {
        width: 95%;
    }
    
}