.profile-box-container {
    max-width: 100%;
}

.profile-box-upper {
    min-height: 300px;
}

.profile-box-lower {
    min-height: 130px;
}

.profile-box-cover-img-container {
    padding: 10px 20px;
    height: 300px;
    width: 100%;
}

.profile-box-cover-img {
    border-radius: 10px !important;
}

.profile-box-user-info {
    margin: 0 0 0 0px;
    padding: 1rem 0 1rem 1rem;
}

.profile-box-profile-img {
    width: 100%;
    transform: scale(1.1);
    object-fit: contain;
}

.profile-box-profile-img-container {
    overflow: hidden;
    position: absolute;
    top: -70px;
    left: 100px;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    border: 8px solid #fff;
}

.profile-box-upper-play-button {
    position: absolute;
    top: 36%;
    left: 48%;
    padding: 0;
    border: none;
    color: white;
    background: transparent;
}

.profile-box-upper-edit-button {
    position: absolute;
    top: 100px;
    right: 4%;
    left: auto;
    padding: 0;
    border: none;
    color: white;
    background: transparent;
}

.profile-box-lower-edit-button {
    padding: 0;
    border: none;
    color: black;
    background: transparent;
    position: absolute;
    right: 40px;
    top: 10px;
}

.profile-box-details {
    padding: 30px;
    position: absolute;
    left: 300px;
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.profile-box-user-info-name {
    font-size: 22px;
    font-weight: 600;
    color: #000339;
}

.profile-box-user-info-job {
    font-size: 14px;
    font-weight: 500;
    color: #000339;
}

.profile-box-user-info-place {
    font-size: 14px;
    font-weight: 400;
    color: #000339;
}

.profile-box-empty-profile-photo {
    height: 60px;
    width: 60px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-box-lower-right {
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 400;
    min-width: 380px;
}

.progress {
    width: 80%;
} 

.profile-box-progress-para {
    margin-top: 10px;
    color: #000339;
}

.pen-edit-icon {
    position: absolute;
    top: 15px;
    left: 91%;
}

.mt-50 {
    margin-top: 50px;
}

.profile-all-component-container {
    margin: 20px 40px;
}

.custom-col {
    padding: 0 40px !important; 
}

.profile-resume-container-parent {
    display: flex;
    flex-direction: row;
}

.profile-resume-container {
    width: 75%;
}

.profile-resume-container-link {
    text-decoration: none;
    color: #000339;
}

.profile-resume-container-second {
    margin-left: 10px !important;
}

.edit-modal-profile-img {
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.edit-modal-input-box {
    border: none;
    outline: none;
    background: #F8F8F8;
    border-radius: 10px;
    padding: 13px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #000339;
}

.edit-modal-close {
    position: absolute;
    top: 20px;
    right: 2px;
    padding: 0;
    background-color: transparent;
    border: none;
}

.edit-modal-social-link-label {
    margin: 1rem 0;
    padding: 0 20px !important;
    font-size: 14px;
    font-weight: 600;
}

.edit-modal-save-button {
    padding: 10px 80px;
}

.video-resume-player {
    padding: 2rem 0;
    border-radius: 10px !important;
    width: 100%;
    height: 500px;
}

.video-resume-edit-button {
    border: none;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 18px;
    background: #fff;
}

.edit-video-pop {
    border-radius: 20px !important;
}

.edit-modal-child-container {
    padding: 0 2rem !important;
}

.profile-outlined-add-btn {
    font-size: 13px;
    font-weight: 600;
    margin: 50px auto;
    border: 1px solid #2F44FF;
    color: #2F44FF;
    border-radius: 8px;
    padding: 8px 12px;
    min-width: 150px;
    background-color: white;
}

.public-link-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.add-details-button {
    position: absolute;
    right: 10px;
}

.public-profile-play-video-btn {
    position: absolute;
    top: 18%;
    left: 47%;
    border: none;
    color: white;
    background: transparent;
}

@media screen and (max-width: 992px) {
    .profile-all-component-container {
        margin: 20px 40px
    }

    .profile-box-profile-img-container {
        left: 70px;
    }

    .profile-box-details {
        left: 250px;
    }

    .profile-box-user-info {
        margin: 0 0 0 90px;
        font-size: 12px !important;
    }

    .profile-box-upper-play-button {
        top: 190px;
    }

    .profile-box-upper-edit-button {
        top: 110px;
        right: 50px;
    }

    .custom-col {
        padding: 0 15px !important; 
    }

    .profile-box-lower {
        min-height: 80px;
    }

    .profile-box-lower-right {
        flex-direction: column;
        min-width: 200px;
    }

}

@media screen and (max-width: 768px) {

    .profile-box-empty-profile-photo {
        height: 40px;
        width: 40px;
        top: 20px;
    }

    .profile-box-profile-img-container {
        top: -30px;
        left: 50px;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        border: 3px solid #fff;
    }

    .profile-box-profile-img {
        object-fit: cover;
        height: 100%;
    }

    .profile-box-details {
        left: 170px;
        width: 65%;
        padding: 10px 20px;
    }

    .profile-modal-content::-webkit-scrollbar {
        display: none !important;
    }

    .profile-modal-content {
        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;
    }

    .profile-box-lower {
        min-height: 80px;
    }

    .profile-resume-container-parent {
        display: flex;
        flex-direction: column;
    }

    .profile-resume-container-second {
        margin-top: 10px;
        margin-left: 0 !important;
    }

}

@media screen and (max-width: 576px) {
    .profile-all-component-container {
        margin: 20px 30px
    }

    .profile-box-upper {
        min-height: auto !important;
    }

    .profile-box-user-info {
        margin: -20px 0 0px -20px;
        padding: 0 1rem !important;
    }

    .profile-box-cover-img-container {
        padding: 0px;
        height: 200px;
        width: 100%;
    }

    .profile-box-cover-img {
        border-radius: 0px !important;
    }

    .profile-box-lower-left {
        margin-top: -70px !important;
    }
    
    .profile-box-profile-img-container {
        top: 50px;
        left: 20px;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        border: 5px solid #fff;
        padding: 0 !important;
    }


    .profile-box-upper-play-button {
        top: 150px;
    }

    .profile-box-upper-edit-button {
        top: 90px;
        right: 30px;
    }

    .profile-box-lower-edit-button {
        right: 25px;
        top: 80px;
    }

    .profile-box-user-info-name {
        font-size: 16px;
        font-weight: 600;
    }

    .profile-box-user-info-job {
        font-size: 12px;
        font-weight: 500;
    }

    .profile-box-user-info-place {
        font-size: 12px;
        font-weight: 400;
    }

    .profile-box-empty-profile-photo {
        height: 40px;
        width: 40px;
    }

    .profile-box-cover-img-label {
        height: 80px;
        width: 80px;
    }

    .profile-box-lower-right {
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
    }

    .progress {
        width: 100px;
    }

    .profile-box-progress-para {
        margin-top: 5px;
    }

    .edit-modal-social-link-label {
        margin: 1rem 0;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
    }

    .video-resume-player {
        padding: 0rem 0;
        border-radius: 10px !important;
        width: 100%;
        height: 800;
        object-fit: contain;
    }

    .video-resume-player-container {
        padding: 30px 0;
    }

    .edit-modal-child-container {
        padding: 0 0.5rem !important;
    }

    .edit-modal-social-link-label {
        margin: 1rem 0;
        padding: 0 0px;
        font-size: 14px;
        font-weight: 600;
    }

    .profile-all-component-container {
        margin: 10px;
    }

    .profile-resume-container {
        width: 90%;
    }    

    .profile-box-details {
        flex-direction: column;
        left: 140px;
        top: 70px;
        padding: 15px;
        width: 50%
    }

    .video-modal-content {
        padding: 0 !important;
    }

    .profile-box-lower {
        min-height: 180px;
    }

    .public-profile-play-video-btn {
        top: 10%;
    }

}

