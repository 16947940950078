.footer-custom {
    background:#000339;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
}

.footer-logo-img-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.footer-para {
    flex-grow: 10;
    font-size: 14px;
    font-weight: 400;
    color: #F8F8F8;
    text-align: center;
    margin-bottom: 0 !important;
}