.signup-container-parent {
    overflow-x: hidden;
}

.signup-container-left {
    background: #2F44FF;
}

.signup-container {
    height: 100vh;
}

.signup-icon-img {
    padding: 4rem;
    height: 100%;
    width: 100%;
}

.signup-form-container {
    width: 450px;
    padding: 50px 0;
}

.signup-form-heading-custom {
    font-size: 24px;
    font-weight: 600;
    margin: 50px 0 30px;
}

.terms-checkbox {
    margin-right: 10px;
}

.get-otp-button {
    margin-top: 35px;
    padding: 10px 30px;
}

.submit-button-custom {
    padding: 12px;
    font-size: 16px;
}

.social-icon-container {
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    padding: 8px;
}

.social-icon-img {
    height: 30px;
    width: 30px;
}

.back-button-container {
    display: none;
}

.logo-img {
    height: 60px;
    width: 100px;
    top: 30px;
    left: 40px;
    position: absolute;
}

.resend-otp-button {
    margin-top: 0px;
}

.password-show-toggle-button {
    height: 10px;
    width: 10px;
    border: 0;
    background: transparent;
    position: absolute;
    top: 10px;
    right: 30px;
    color:#C4C4C4;
}

.modal-container-forget-password {
    width: 55%;
}

.option-items {
    border: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 160px;
    width: 160px;
}

.option-items:focus {
    color: #2F44FF;
}

.option-item-img {
    height: 50px;
    width: 50px;
    margin-top: 20px;
}

.or-continue-div {
    border-top: 1px solid #EFEFEF;
    margin-top: 60px;
    padding: 0 80px;
}

.or-continue-text {
    margin-top: -10px;
    background: white;
    width: 150px;
}

.user-type-selected {
    background-color: #D8E9FF;
}

@media screen and (max-width: 992px) {

    .signup-icon-img {
        padding: 1rem;
        height: 400px;
        width: 370px;
    }

    .option-items {
        border: 1px solid #EFEFEF;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 120px;
        width: 120px;
    }

    .option-item-img {
        height: 40px;
        width: 40px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {

    .modal-container-forget-password {
        width: 94%;
        height: 50%;
    }

    .signup-icon-img {
        height: 300px;
        width: 300px;
    }

    .get-otp-button {
        padding: 10px 25px;
    }

    .signup-container-left-final {
        height: 0;
    }

    .signup-form-heading-custom {
        font-size: 18px;
        font-weight: 600;
        margin: 50px 0 30px;
    }

    .back-button-container {
        display: inline;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 40px;
        left: 20px;
    }

    .signup-container-left {
        padding-right: 0 !important;
        width: 100% !important;
    }

    .logo-img {
        height: 30px;
        width: 45px;
        top: 30px;
        left: 30px;
        position: absolute;
    }

    .resend-otp-button {
        margin-top: 35px;
    }

    .signup-container {
        height: auto;
    }

}