.opportunities-banner-container {
    position: relative;
    text-align: center;
}

.opportunities-banner-text {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 48px;
    position: absolute;
    color: white;
    top: 30%;
    font-weight: 600;
    text-align: center;
}

.opportunities-content-container {
    margin: 10px 60px;
}

@media screen and (max-width: 992px) {
    .opportunities-content-container {
        margin: 10px 40px;
    }

    .opportunities-banner-text {
        font-size: 36px;
        top: 25%;
    }
}
@media screen and (max-width: 768px) {
    .opportunities-content-container {
        margin: 10px 30px;
    }

    .opportunities-banner-text {
        font-size: 32px;
        top: 22%;
    }
}

@media screen and (max-width: 576px) {
    .opportunities-content-container {
        margin: 10px 2px;
    }

    .opportunities-banner-text {
        font-size: 28px;
        top: 18%;
    }
}