.virtual-exp-banner-container {
    background-image: url("./Media/ve-banner.png");
    height: 240px;
    color: white;
}

.virtual-exp-banner-heading-container {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 60px
}

.virtual-exp-banner-content {
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 5px;
    padding: 0 40px;
}

.ve-main-container {
    margin: 10px 60px 80px;
}

.filter-container {
    margin: 70px 0;
    padding: 30px 20px;
    border: 1px solid rgb(241, 241, 241);
}

.ve-filter-tab {
    border: none;
    color: #000339;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    background-color: white;
}

/* bootstrap class is overridden to make the filter drop down scrollable */
.ve-filter-tab + .dropdown-menu {
    padding: 8px 15px 0;
    font-size: 12px;
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 992px) {
    .ve-main-container{
        margin: 10px 40px 80px;
    }

    .non-enrolled-virtual-experiences {
        justify-content: center;
    }
}
@media screen and (max-width: 768px) {
    .ve-main-container {
        margin: 10px 20px 50px;
    }

    .virtual-exp-banner-container {
        background-image: url("./Media/ve-phone-banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 700px;

    }

    .virtual-exp-banner-heading-container {
        top: 120px
    }
}

@media screen and (max-width: 576px) {
    .ve-main-container {
        margin: 10px;
    }
}

.lh-60 {
    line-height: 60px;
}

.lh-33 {
    line-height: 33.5px;
}

.bg-container {
    background-image: url("../virtualExperiences/Media/ve_bg_img.png");
    background: linear-gradient(89.8deg, rgba(5, 2, 21, 0.56) -1.83%, rgba(6, 6, 6, 0.3136) 93.34%);;
}

.carousel-control-custom-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:#000339;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .bg-container {
        background-image: url("../virtualExperiences/Media/ve_bg_mob.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
    }
}

.lh-33 {
    line-height: 33px;
}

.lh-38 {
    line-height: 38px;
}