.edit-modal-close {
    position: absolute;
    top: 10px;
    right: 12px;
    padding: 0;
    background-color: transparent;
    border: none;
}

.edit-modal-social-link-label {
    margin: 1rem 0;
    padding: 0 0px;
    font-size: 14px;
    font-weight: 600;
}

.edit-modal-save-button {
    padding: 10px 80px;
}

.edit-modal-input-box {
    border: none;
    background: #F8F8F8;
    border-radius: 10px;
    padding: 13px 15px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000339;
}

.add-skill-button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EFF1FF;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #2F44FF;
    float: right;
}

.add-skill-button-modal:hover {
    cursor: pointer;
}

.modal-save-button {
    padding: 10px 70px !important;
}

.profile-add-modal-check label {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000339 !important;
}

.profile-add-modal-check input {
    height: 18px !important;
    background-color: #C4C4C4 !important;
    border-color: #C4C4C4 !important;
    margin-right: 10px !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.profile-add-modal-check input:focus {
    border-color: #C4C4C4 !important;
    box-shadow: none !important;
}

.profile-add-modal-check input:focus:checked {
    border-color: #50D28A !important;
}

.profile-add-modal-check input:checked {
    background-color: #50D28A !important;
}

.single-skill {
    font-size: 14px;
    font-weight: 500;
    color: #2F44FF;
    padding: 10px 20px;
    margin: 10px;
    background-color: #EFF1FF;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    
    .profile-add-modal-check input {
        margin-bottom: 20px;
    }

    .profile-modal-content::-webkit-scrollbar {
        display: none !important;
    }

    .profile-modal-content {
        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;
    }

}

@media screen and (max-width: 576px) {

    .add-skill-button-modal {
        margin-right: 0px;
    }

    .about-me-modal-body {
        height: 50% !important;
    }
}

