.selected-button-custom {
    display: inline;
    margin-left: 8px;
    border-radius: 10px;
    padding: 10px 18px;
    color: #ffffff;
    position: relative;
}

.unselected-button-custom {
    margin-left: 8px;
    border: none;
    border-radius: 10px;
    padding: 10px 18px;
    color: #000339;
    position: relative;
}

.button-close-custom {
    color: #ffffff;
    padding: 3px;
    padding-top: 0px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #2F44FF;
    position: absolute;
    border: 0;
    top: -5px;
    right: -5px;
}

.apply-button-custom {
    width: 160px;
    height: 44px;
}

.accordion-container-custom {
    display: flex;
    justify-content: space-between ;
}

.close-button-custom {
    padding: 0;
    border: 0;
    position: absolute;
    background-color: transparent;
    top: 20px;
    right: 25px;
}

.modal-content {
    border-radius: 1rem !important;
    padding: 0 33px;
    border: none !important;
    z-index: 10000 !important;
}

.accordion-button {
    color: #000339 !important;
}

.accordion-button:not(.collapsed) {
    background: #fff !important;
    box-shadow: none !important;
    color: #000339 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.filter-modal-body {
    margin: 20px 40px;
}

@media screen and (max-width: 576px) {

    .accordion-container-custom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unselected-button-custom {
        width: 50%;
        margin-left: 0 !important;
        margin-bottom: 15px;
    }

    .selected-button-custom {
        width: 50%;
        margin-left: 0 !important;
        margin-bottom: 15px;
        text-align: center;
    }

    .filter-modal-body {
        margin: 15px 5px;
    }

}