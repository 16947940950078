.notification-dropdown-container {
    min-width: 400px;
    max-height:400px;
    overflow-y: scroll;
}

.notification-dropdown-container::-webkit-scrollbar {
    display: none;
}

.notification-setting-button {
    top:20px;
    right: 25px;
}

.notification-back-button {
    top:20px;
    left:25px;
}